.sidebar {
  background-size: cover;
  background-position: 50% 0;
  box-shadow: 3px 3px 5px #ccc;
  color: rgb(255, 255, 255);
  color: rgba(255, 255, 255, 0.5);
  padding: 2rem 0.5rem;
  text-align: center;
  a {
    color: $gray-1;
    border: none;
    &:hover {
      color: $color-sidebar;
    }
    &:focus {
      color: $color-sidebar;
    }
  }
  .sidebar-about {
    text-align: center;
    margin-top: 16rem;
  }
  .author-image {
    display: block;
    margin-top: 4px;
  }
}

.sidebar-nav {
  text-align: center;
  list-style: none;
  margin-bottom: 2rem;
  margin-top: 2rem;
  padding-left: 0;
  li {
    padding: .25rem 0;
  }
}

.sidebar-nav-item {
  display: block;
  line-height: 1.75;
  .active {
    font-weight: bold;
  }
}

.pretitle {
  font-family: $site-title-embellishment-font;
  font-size: 2rem;
  display: inline-block;
  padding-right: 0.1em;
  color: $gray-7;
}

.site__title {
  font-family: $site-title-font;
  font-weight: $title-weight;
  text-transform: uppercase; 
  font-size: 1.9rem;
  margin-bottom: 0.5rem;
  a:hover {
    border: none;
  }
}

.posttitle {
  font-family: $site-title-font;
  color: $gray-4;
}

.site__description {
  font-size: 1.285rem;
  font-weight: 300;
}

.social {
  text-align: center;
  a {
    padding: 0 4px;
    @include link-no-decoration();
  }
}

.img--circle {
	border-radius: 50%;
}

.img--headshot {
	height: 115px;
	width: 115px;
}

.img--caption {
	font-style: italic;
}

%small-center-text {
  font-size: $font-scale-dot7;
  line-height: 1.1rem;
  text-align: center;
}

.copyright {
  color: $gray-4;
  margin: 0 auto;
  padding-top: 2em;
	@extend %small-center-text;
}
.builtwith {
  padding-top: .2rem;
  @extend %small-center-text;
}
