// for the list of posts

.section__title {
	font-size: $section__title-font-size;
	font-family: $site-title-font;
	text-transform: uppercase;
}

ul.posts {
	list-style-type: square;
	padding-inline-start: 1.2em;
	li {
		padding-bottom: 0.5em;
    	border-bottom: 2px solid $gray-2;
    	margin-bottom: 0.5em;
	}
}

.post-list__item {
	padding-bottom: 1em;
    border-bottom: 2px solid $gray-2;
    margin-bottom: 1em;
}

.list__title--small a {
	font-family: "Lato";
	font-weight: 700;
}

.list__title--small a:hover {
	border-bottom: 2px solid;
}

.item__title--big {
	font-family: $site-title-font;
	font-weight: $title-weight;
	text-transform: uppercase;
	display: block;
	font-size: $item__title-big-font-size;
	line-height: 1.25;
	margin-bottom: 0.5rem;
	a:hover {
		border-bottom: 3px solid;
	}
}

.item__title--small {
	font-size: 1rem;
	a {
		border-bottom: 3px solid;
	}
}

.item__date {
	color: $item__date-color;
	display: block;
	font-size: $item__date-font-size;
	margin-bottom: .2rem;
	margin-top: .2rem;
}
